ion-app {
  // padding-top: env(safe-area-inset-top);
  // margin-bottom: env(safe-area-inset-bottom)
}

.overflow-x-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
