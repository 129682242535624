ion-checkbox {
  margin-right: 0;
  --border-color: var(--ion-color-medium);
  --checkbox-background: white;
  --size: 24px;
  --border-width: 1px;
  --border-radius: 8px;
  --border-color-checked: var(--ion-color-success);
  --checkbox-background-checked: var(--ion-color-success);
  &::part(label) {
    @apply py-0 my-0;
    margin-inline-start: 10px;
  }
  span {
    @apply whitespace-normal;
  }
  .checkbox-wrapper {
    @apply items-center #{!important};
  }
}
