ion-toolbar {
  /* HACK: This should be from a variable for consistency across the app */
  --background: transparent;
  padding-top: env(safe-area-inset-top);
}
ion-toolbar.bg-transparent {
  --background: transparent;
}
ion-toolbar.h-auto {
  --min-height: auto;
}

ion-toolbar {
  ion-buttons {
    ion-button {
      @apply tracking-normal;
      --padding-start: 0px !important;
      width: fit-content !important;
      height: fit-content !important;
    }

    ion-menu-button {
      --padding-start: 16px !important;
      width: fit-content !important;
      height: fit-content !important;
    }
  }
}
