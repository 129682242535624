.alert-card {
  width: 100%;
  padding: 12px 18px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 18px;
  }
  p {
    margin: 0px;
    text-align: left;
    small {
      color: #ffffff;
    }
  }
  &--success {
    @extend .alert-card;
    background-color: #d4edda;
    border-color: #c3e6cb;
    p small {
      color: #102a51;
    }
  }
  &--error {
    @extend .alert-card;
    background-color: #fccbc7;
    p small {
      color: #730900;
    }
  }
}
