ion-grid {
  padding: 0;

  ion-row {
    padding: 0;

    ion-col {
      padding: 0;
    }
  }
}