ion-button {
  min-height: auto;
  &[size='small'] {
    height: 40px;
    font-size: 16px;
    --padding-bottom: 0;
    --padding-top: 0;
    --padding-start: 16px;
    --padding-end: 16px;
  }
  --border-radius: 100px;
  text-transform: none;

  --box-shadow: none;
  --background-hover-opacity: 0;
  --background-focused-opacity: 0;
  letter-spacing: 0;
  @apply font-semibold;
  &:not([fill='clear']) {
    &[size='large'],
    &:not([size]) {
      @apply sm:max-w-button w-full  mx-auto;
      --padding-top: 0;
      --padding-start: 1em;
      --padding-end: 1em;
      --padding-bottom: 0;
      height: 54px;
      font-size: 18px;
    }
  }
  &.icon {
    --padding-top: 0;
    --padding-start: 3px !important;
    --padding-end: 3px !important;
    --padding-bottom: 0;
  }
}
ion-button.bg-transparent {
  &::part(native) {
    background: transparent;
    --background: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
  }
}

ion-menu-button {
  --background-hover: none !important;
  --padding-end: 0px !important;
}
