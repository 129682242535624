.wrapper {
  @apply flex items-stretch gap-[11px] px-[2px];
  .otp-input {
    @apply text-3xl font-semibold border-medium border-solid rounded-[10px] p-2 pt-2 pb-1 w-full h-auto m-0 align-middle md:w-[57px] md:h-[68px] #{!important};
  }
  .otp-input:focus {
  }

  .otp-input::placeholder {
    color: #d9dbe9;
  }
}
