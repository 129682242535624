app-home {
  swiper-container .swiper-pagination-bullet {
    --bullet-background-active: var(--ion-color-primary) !important;
  }
}
swiper-container {
  --swiper-pagination-bullet-size: 12px;
  .swiper-pagination {
    position: absolute !important;
    bottom: 10px !important;
    height: 20px !important;
  }

  .swiper-pagination {
    position: relative;
    bottom: -6px;
    height: 55%;
    padding-top: 0;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;
    --bullet-background: #d9dbe9;
    --bullet-background-active: var(--ion-color-success);
    opacity: 1.2;
  }

  swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 9px;
    left: 0;
    width: 100%;
  }

  .swiper-wrapper {
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  .swiper-container {
    height: 63%;
    margin: 0 auto;
    position: fixed;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
}
