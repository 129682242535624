h1 {
  @apply font-semibold text-3xl #{!important};
}

h2 {
  @apply leading-[33px] text-2xl font-semibold m-0 #{!important};
}

h3 {
  @apply text-xl font-semibold leading-[22px] my-0 #{!important};
}

h4 {
  @apply text-md leading-6 my-0 #{!important};
}

h5 {
  @apply text-base leading-6;
}

h6 {
  @apply text-xs leading-6 #{!important};
}

p {
  @apply m-0 p-0 text-base leading-[19px] #{!important};
}

textarea {
  @apply tracking-tightest leading-6;
}
a {
  @apply text-primary underline decoration-solid;
}

.card-content-md p {
  padding: 0;
  margin: 0;
}
