ion-card {
  border-radius: 12px;
  margin: 0;
  --color: var(--ion-color-dark);

  ion-card-header {
    @apply text-md #{!important};
    ion-item {
      width: 100%;
    }
  }
}
