/** Style of ion-popover */
ion-popover {
  --backdrop-opacity: 0.7;
  --width: 80%;
}

.popover-primary-wrapper {
  --background: var(--ion-color-primary);
  --width: 241px;
  @screen md {
    --width: 333px;
  }
  &::part(content).sc-ion-popover-md {
    border-radius: 10px;
  }
}

.popover-translucent.sc-ion-popover-ios-h::part(content).sc-ion-popover-ios,
.popover-translucent.sc-ion-popover-ios-hr::part(arrow).sc-ion-popover-ios::after {
  background: var(--ion-color-primary);
}

.profile-page {
  &.slideDown {
    .header-container {
      transition: all 0.35s;
      position: fixed;
      width: 100%;
      z-index: 2;
      max-height: 0px;
    }
  }

  &.slideUp {
    .header-container {
      transition: all 0.35s;
      position: fixed;
      width: 100%;
      z-index: 2;
      top: 43px;
      height: 80px !important;
      max-height: 80px !important;
    }
  }

  &.slideUp .hero-container {
    margin-top: 12vh !important;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
