ion-searchbar {
  --border-radius: 10px !important;
  border-radius: 10px;
  --background-color: white;
  --box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.51) !important;
  height: 40px;
  padding-inline-start: 0px !important;
  padding-inline-end: 0px !important;
  border: 1px solid #d9dbe9 !important;

  input {
    background-color: transparent !important;
    box-shadow: none !important;
    height: 40px !important;
    border: none !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
  }

  .sc-ion-searchbar-md {
    border-radius: 10px !important;
    background-color: transparent !important;
  }

  ion-icon {
    color: #a0a3bd !important;
  }
}

.sc-ion-searchbar-ios {
  border-radius: 10px !important;
  background-color: white !important;
}

.sc-ion-searchbar-md {
  border-radius: 10px !important;
  background-color: white !important;
}
