/** ION PICKER */
.ion-page {
  ion-picker {
    .picker-wrapper {
      .picker-toolbar {
        justify-content: space-between !important;
      }
    }
  }
}

:host(.menu-side-start) .menu-inner {
  --width: 100% !important;
}
ion-split-pane {
  --side-max-width: 313px;
}
ion-menu {
  --width: 100% !important;
  --min-width: 100% !important;
  @screen sm {
    --width: 320px !important;
    --min-width: 320px !important;
  }
  @screen lg {
    --width: 313px !important;
    --min-width: 313px !important;
  }
  ion-content {
    --background: var(--ion-color-primary) !important;
    --width: 100% !important;
    --min-width: 100% !important;
  }
}

ion-modal {
  --backdrop-opacity: 70%;
  ion-toolbar.main-toolbar {
    --background: #ffffff !important;
    --ion-color-base: #ffffff !important;
  }
}

ion-popover {
  &::part(content) {
    border-radius: 10px;
    border: none;
  }
}
