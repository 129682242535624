/*Base*/
@import 'application/base/vars';
/*Layouts*/
@import 'application/layouts/alert-card.scss';
@import 'application/layouts/layout.scss';
@import 'application/layouts/ionic-components-overwritten';
/*Theme*/
@import 'application/theme/input';

hr {
  @apply w-full m-0 my-1.5 border-b-[#D9DBE9];
}
