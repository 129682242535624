/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@tailwind components;
@tailwind utilities;

/* Variables for consistency */
@import './theme/spacing';

/* Elements reset */
@import './theme/resets/grid';
@import './theme/resets/ion-item';
@import './theme/resets/ion-card';
@import './theme/resets/ion-list';

/* Custom components */
@import './theme/components/app-input';

/* Custom overrides */
@import './theme/overrides/ion-button';
@import './theme/overrides/ion-content';
@import './theme/overrides/typography';
@import './theme/overrides/ion-toolbar';
@import './theme/overrides/ion-tabs';
@import './theme/overrides/ion-modal-dialog';
@import './theme/overrides/ion-segment';
@import './theme/resets/ion-app';
@import './theme/overrides/ion-searchbar';
@import './theme/overrides/_ion-slides';
@import './theme/overrides/header';
@import './theme/overrides/datepicker';
@import './theme/overrides/misc';
@import './assets/styles/application/theme/pages/create-account/ion-otp-input';
@import './assets/styles/application/theme/pages/complete-profile/complete-profile-modal.scss';
@import './theme/components/toast';
@import './theme/overrides/ion-radio';
@import './theme/overrides/ion-checkbox';
@import './assets/styles/application';
