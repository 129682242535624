ion-tab-bar {
  @apply md:hidden max-h-[55px];
  ion-tab-button {
    &::part(native) {
      --padding-end: 0px;
      --padding-start: 0px;
    }
  }
}

ion-tab-bar {
  ion-icon {
    @apply w-4 h-4;
  }
}
