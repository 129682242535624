ion-skeleton-text {
  line-height: 22px;
}
@screen lg {
  body {
    overflow: overlay;
  }
}
* {
  font-family: var(--ion-font-family);
}

ul {
  margin: 0px !important;
  padding: 0px 0px 0px 25px;
  font-size: 16px !important;
  align-items: center;
  letter-spacing: 0.75px !important;
  @apply text-dark;
}

//text-ellipsis
.ellipsis-2 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  word-wrap: break-word;
  white-space: break-spaces;
}

ion-card {
  @apply shadow-md;
}

.header-md::after {
  background-image: none;
}

ion-header::after {
  display: none;

  .toolbar-container {
    padding: 0px;
  }
}

//define class general button
ion-card {
  @apply text-dark;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  ion-card-header {
    @apply px-[24px] py-[20px];
  }
  ion-card-content {
    @apply text-base px-[24px] py-[20px] #{!important};
    p {
      @apply text-base #{!important};
    }
  }
}

a {
  @apply font-semibold no-underline;
}

a.disabled {
  cursor: text;
  pointer-events: none;
  text-decoration: none;
}

.swiper-slide {
  @apply text-base;
  text-align: initial;
}

//pages only notification success or info
.screen-notify {
  @apply text-center h-full;
  h2 {
    @apply pt-4;
  }
  h3 {
    @apply mt-1;
  }
  section {
    @apply min-h-3/4 md:h-auto flex items-stretch;
  }
  footer {
    /* @apply py-2; */
  }
  .icon-notify {
    @apply rounded-full p-3  bg-accent text-5xl h-18 w-18 mx-auto align-middle self-center;
  }
}

//container unauth
#unauth-content {
  @apply h-full;
  .screen-notify {
    @apply h-full;
  }

  .main,
  main {
    @apply flex-grow flex flex-col;
  }

  footer {
    @apply block w-full;
  }

  .main-btn-back {
    --padding-start: 0;
    --padding-end: 0;
  }
}

hr {
  @apply bg-medium my-4;
}

.swiper-zoom-container {
  justify-content: first baseline;
}

ion-label {
  p {
    @apply text-base #{!important};
  }
}

ion-input {
  @apply rounded-lg;
  --padding-start: 16px !important;
  --padding-end: 16px !important;
  --padding-top: 8px !important;
  --padding-bottom: 8px !important;
}

.datetime-placeholder {
  color: rgb(160, 163, 187, 0.5);
}

//style layout content
#base-content {
  .logo-color {
    @apply ml-4;
  }
  & + main {
    @apply px-4 pt-2 pb-4 overflow-y-scroll;
    @apply inset-0 absolute;
    @screen sm {
      @apply overflow-y-auto inset-auto static;
    }
    section.body,
    .section-body {
      @apply min-h-3/4 md:min-h-auto md:h-auto md:pt-8;
    }
  }
}

ion-menu {
  --border: 0;
}

.alert-card--error {
  p {
    line-height: 1rem;
  }
}

.wrapper .otp-input {
  background: white;
  @apply text-dark-700;
}

ion-footer::before {
  background: none !important;
}

ion-footer {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.webkit-center {
  text-align: -webkit-center;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.input-amount {
  .native-input.sc-ion-input-md {
    padding-bottom: 0px !important;
    height: 66px;
  }
}
.input-refer {
  .native-input.sc-ion-input-md {
    --padding-end: 12px !important;
    --padding-start: 12px !important;
  }
}

.position-x {
  background-position-x: 96% !important;
}

ion-menu-button {
  img {
    filter: invert(1) !important;
    @apply h-[18px] w-[18px];
  }
}

ion-grid,
ion-col,
ion-row {
  @apply mx-0 p-0;
}

#siqcht #siqiframe {
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

[hidden] {
  display: none !important;
}
a {
  @apply underline;
}
