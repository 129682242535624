ion-toast {
  &.ion-color-success {
    --background: var(--ion-color-success);
    --ion-color-contrast: var(--ion-color-dark) !important;
  }
}
#toast-notification {
  --border-radius: 0px;
  --box-shadow: none;
  top: -10px;
  --end: 0;
  --start: 0;
  @screen md {
    --start: 313px;
  }
  @screen lg {
    top: calc(56px + var(--ion-safe-area-top, 0px));
    --border-radius: 0px;
    --box-shadow: none;
    top: -10px;
    --end: 0;
    --min-height: 54px;
    --max-width: 100%;
  }

  --min-height: 54px;
}
