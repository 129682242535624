/* TODO: Move to _app-input.scss*/
ion-textarea {
  --border-radius: 12px !important;
  &.icon-right {
    --padding-end: 36px !important;
  }
  --border-color: #e4e9f2 !important;

  &.has-focus {
    --border-width: 1px;
    --border-color: var(--ion-color-primary) !important;
  }
  textarea {
    caret-color: var(--ion-color-primary) !important;
  }
}

ion-input {
  height: 40px;
  min-height: 40px !important;
  border: 1px solid #e4e9f2;
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='textarea'] {
    --placeholder-color: #8f9bb3;
    // color: #090D19 !important;
    box-sizing: border-box !important;
    --padding-top: 8px;
    --padding-end: 16px;
    --padding-bottom: 8px;
    --padding-start: 16px;
  }

  &.danger-input {
    border: 1px solid #ff3d71;
  }

  &.ng-invalid.ion-dirty {
    border: 1px solid var(--ion-color-warning);
  }

  &.has-focus {
    border: 1px solid #0095ff;
  }

  label .input-highlight {
    display: none !important;
  }
}

select {
  height: 40px;
  border: 1px solid #e4e9f2;
  width: 100%;
  border-radius: 8px;
  outline-width: 0px;
  color: var(--ion-color-dark);
  padding: 4px 12px 4px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../../icon/ic_chevron_down_light_gray.svg') 95%/16px no-repeat;
  option {
    --placeholder-color: #8f9bb3;
    box-sizing: border-box !important;
    --padding-top: 8px;
    --padding-end: 16px;
    --padding-bottom: 8px;
    --padding-start: 16px;
  }

  &.danger-input {
    border: 1px solid #ff3d71;
  }

  select:required {
    border: 1px solid var(--ion-color-warning) !important;
  }

  &:focus {
    border: 1px solid #0095ff !important;
  }
}

.select-disabled {
  select:disabled {
    @apply text-dark opacity-100 border-none bg-none p-0;
    border: none !important;
  }
}

select:disabled {
  color: #8992a3;
}

.input-disabled {
  input:disabled {
    @apply text-dark opacity-100 border-transparent bg-none #{!important};
    --padding-start: 0px;
  }

  ion-input {
    border: none !important;
  }
}

.input-title {
  @apply font-semibold text-dark text-left;
}

.input-name {
  @apply font-semibold;
}

.input-group {
  position: relative;

  .input-currency {
    --background: white;
    --color: var(--ion-color-dark);
    border-radius: 8px !important;
    --placeholder-color: #8f9bb3;
    box-sizing: border-box !important;
    height: 40px !important;
    padding: 8px 16px !important;
    border: 1px solid #e4e9f2 !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    text-align: left !important;
    width: 100%;
  }
}

.input-group-append.hide-password {
  position: absolute;
  z-index: 2;
  right: 6px;
  padding: 8px 0px;
  top: 0px;
  height: 100%;

  .input-group-text {
    background-color: transparent;
    border: none;

    .img-calendar-gray {
      width: 20px;
      margin-right: 9px;
      padding-top: 6px;
    }
  }
}

.input-errors {
  .input-errors-warning {
    text-align: left;
    color: orange;
    margin: 8px 0px 0px 0px;
    text-transform: initial;
  }
  .input-errors-danger {
    text-align: left;
    color: rgb(235 68 90);
    margin: 8px 0px 0px 0px;
    text-transform: initial;
  }
}

.item-date {
  --min-height: 42px !important;
}

ion-popover#popoverDatetime::part(content) {
  @apply w-4/5 rounded-none top-[28%] left-[10%] sm:w-auto sm:left-[35%];
}

ion-popover.popoverDatetime ion-datetime {
  margin-left: auto;
  margin-right: auto;
  --background: white;
}

@media (min-width: 576px) and (max-width: 2800px) {
  .app-input {
    .input-title .input-name {
      color: #102a51;
      letter-spacing: 0;
      line-height: 16px;
    }
  }

  ion-input {
    // border-radius: 8px !important;
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='number'],
    input[type='textarea'] {
      box-sizing: border-box;
      height: 48px;
      border: 1px solid #8f9bb3;
      border-radius: 8px !important;
    }
  }

  .input-group {
    position: relative;

    .input-currency {
      height: 48px !important;
      border: 1px solid #8f9bb3 !important;
    }
  }

  ion-item.custom-calendar {
    border: 1px solid #8f9bb3;

    ion-datetime {
      height: 100%;
    }
  }
}
