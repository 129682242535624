ion-datetime {
  line-height: 15px !important;
  text-align: left !important;
  padding: 10px;
  border: 1px solid #e4e9f2;
  border-radius: 8px !important;

  &.danger-input {
    border: 1px solid #ff3d71 !important;
  }

  &.ng-invalid.ion-dirty {
    border: 1px solid var(--ion-color-warning);
  }

  &.has-focus {
    border: 1px solid #0095ff;
  }
  ::ng-deep ion-button[id="cancel-button"]{
    text-transform: capitalize;
  }
}
