//create mixin for app-input
.input-title {
  .input-name {
    line-height: 26px;
  }
}
.app-input {
  .input-title {
    .input-name {
      line-height: 26px;
    }
  }

  &--light {
    .input-title {
      .input-name {
        color: #102a51;
      }
    }
  }

  ion-item {
    &.textarea {
      --inner-padding-end: 0px;
    }
  }

  ion-input {
    --background: white;
    --color: var(--ion-color-dark);
    border-radius: 9px;
    /* HACK: We're not sure why it doesn't work without !important. If we inspect
             the element we don't see any overriding this style.
    */
    input {
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 9px !important;
      font-weight: 400 !important;
    }
  }
}
