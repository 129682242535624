ion-segment {
  border-bottom: 1px solid #DFDFDF;
  border-radius: 0;
  --background: white;

  ion-segment-button {
    min-width: auto;
    --border-style: none;
    --color: var(--ion-color-medium);
    --color-checked: var(--ion-color-primary);
    --indicator-box-shadow: none;
    --indicator-color: var(--ion-color-primary);
    --indicator-transform: translateY(95%) scaleX(0.30);
  }
}
